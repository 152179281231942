:root {
  --backgroundColor: black;
  --foregroundColor: rgba(0, 81, 255, 0.993);
  --placeholderColor: var(--foregroundColor);  /*rgb(221, 137, 76) */
  --fieldbackgroundColor:cornsilk;
  --navbarColor:rgb(1, 60, 252);
}

body {
  margin: 0;
  font-family: 'bangers' !important;
  overflow: auto;

}
canvas {
  display:block;  /*needed for no scrollbars */
}
.game {
  overflow: hidden;

}
@font-face {
  font-family: 'bangers';
    src:url(./fonts/Bangers-Regular.ttf) format('truetype');
}

#info {
  max-height: 3vh;
  object-fit: contain;
}
#navbar {
  border-bottom: 1px solid black;
  background-color: var(--navbarColor);
margin-bottom: 1em;

}
#navlogo {
  height: 50px;
  margin-right: .3em;
  object-fit: contain;
}
.navbar-brand {
  font-size: 5vh;
}
.navbar-nav {
  font-size: 1.4rem;
}


#alternate img {
  top:0px;
  left:0px;
  max-height: 100%;

  animation: fade 1s infinite alternate;
  object-fit: contain;
}

#alternate img:nth-child(2) {
  animation-delay: 1s;
}

@keyframes fade {
  0% { opacity: 1; }
  49% { opacity: 1; }
  50% { opacity: 0; }
  100% { opacity: 0; }
}
#popinimage {
  width:15vh ;
  height:26.25vh;
  position: fixed;
  top:89%;
  right:20%;
  animation: in_out 2s infinite alternate;
}

@keyframes in_out {
  0% {
    transform: translate(0em, 0em);
  }
 
  100% {
    transform: translate(0vh, -13vh);
  }
}

.prompt {
  
  position: fixed;
  left: 50%;
  top: 20%;
  width:20em;
  margin-left: -10em; /* Half of the width */
  animation: shake 1s infinite;
  animation: wiggle_updown 1s infinite;
 
}

.do_me {
  animation: wiggle_leftright 1s infinite;
}

.do_me::after {
  content: '';
  display: block;
  position: relative;
  left: 50%;
  margin-left: -.5em;
  top: .2em;
  width: 0;
  height: 0;
  border-top: .5em solid var(--foregroundColor);
  border-left: .5em solid transparent;
  border-right: .5em solid transparent;
  animation: wiggle_updown 1s infinite;
}

@keyframes wiggle_leftright {
  0% {
    transform: translate(-1em, 0em);
  }

  50% {
    transform: translate(1em, 0em);
  }

  100% {
    transform: translate(-1em, 0em);
  }
}

@keyframes wiggle_updown {
  0% {
    transform: translate(0em, -.25em);
  }

  50% {
    transform: translate(0em, .25em);
  }

  100% {
    transform: translate(0em, -.25em);
  }
}

@keyframes shake {
  0% {
    transform: translate(1px, 1px) rotate(0deg);
  }

  10% {
    transform: translate(-1px, -2px) rotate(-1deg);
  }

  20% {
    transform: translate(-3px, 0px) rotate(1deg);
  }

  30% {
    transform: translate(3px, 2px) rotate(0deg);
  }

  40% {
    transform: translate(1px, -1px) rotate(1deg);
  }

  50% {
    transform: translate(-1px, 2px) rotate(-1deg);
  }

  60% {
    transform: translate(-3px, 1px) rotate(0deg);
  }

  70% {
    transform: translate(3px, 1px) rotate(-1deg);
  }

  80% {
    transform: translate(-1px, -1px) rotate(1deg);
  }

  90% {
    transform: translate(1px, 2px) rotate(0deg);
  }

  100% {
    transform: translate(1px, -2px) rotate(-1deg);
  }
}

.push_me {
  animation: shake 1s infinite;
}

@keyframes AutoFillStart {
  from {
    background-color: purple(0%);
  }

  to {
    background-color: red(100%);
  }
}

input:-webkit-autofill {
  animation: AutoFillStart 1s;
  transition: background-color 50000s ease-in-out 0s;
}

.btn-primary, .btn-primary:focus, .btn-primary:active, .btn-primary:hover, .btn-primary.disabled, .btn-primary:disabled {
  color: var(--fieldbackgroundColor);
  background-color: var(--foregroundColor);
  border-color: var(--foregroundColor);
}

body {
  background-color: var(--backgroundColor);
}

body, .form-control:not(active) ,.form-control:focus {
  color: var(--foregroundColor);
}

.myform {
  overflow: auto;

}
.myform input::placeholder, .myform textarea::placeholder {
  color:var(--placeholderColor);
}
.myform>* {
  display: inline-block;
  margin-bottom: 1rem;

}

.myform>*:not(:first-child) {
  margin-left: .5em;
}

.myform>input, .myform .form-group {
  width: 25%;
  min-width: 25em;
}

.world {
  display: block;
}

.unused {
  width: 80%;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}


/*   #refresh ,   */
/*
::::::::::::::::::::::::::::::::::::::::::::::::::::
Bootstrap 4 breakpoints   

/* 
Extra small devices (portrait phones, less than 576px) 
No media query since this is the default in Bootstrap because it is "mobile first"
*/

/* Small devices (landscape phones, 576px and up) */

@media (min-width: 576px) {}


/* Medium devices (tablets, 768px and up) The navbar toggle appears at this breakpoint */

@media (min-width: 768px) {
 
  body, .form-control, .btn {
     font-size:1.5rem
   }
}

/* Large devices (desktops, 992px and up) */

@media (min-width: 992px) {}

/* Extra large devices (large desktops, 1200px and up) */

@media (min-width: 1200px) {}

